<template>
  <transition name="el-zoom-in-center">
    <div class="SNOWS-preview-main">
      <div class="SNOWS-common-page-header">
        <el-page-header @back="goBack" :content="table+'表的数据'"/>
        <div class="options">
          <el-button @click="goBack()">取消</el-button>
        </div>
      </div>
      <div style="padding: 0 10px 10px 10px">
        <el-row class="SNOWS-common-search-box" :gutter="16">
          <el-form @submit.native.prevent>
            <el-col :span="6">
              <el-form-item label="字段">
                <el-select v-model="queryJson.field" filterable placeholder="请选择字段">
                  <el-option v-for="item in options" :key="item.Field" :label="item.Field"
                             :value="item.Field">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="关键词">
                <el-input v-model="queryJson.keyword" placeholder="请输入关键词查询" clearable
                          @keyup.enter.native="search()"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search()">
                  查询
                </el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置
                </el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-table v-loading="listLoading" :data="list" @cell-dblclick="cellDblclick" size="medium" border height="calc(100vh - 304px)">
          <el-table-column :prop="item.Field" :label="item.Field" show-overflow-tooltip
                           v-for="item in options" :key="item.Field" min-width="150"/>
        </el-table>
        <pagination :total="total" :page.sync="listQuery.currentPage" :limit.sync="listQuery.pageSize"
                    @pagination="initData"/>
      </div>
    </div>
  </transition>
</template>

<script>
import {openTable} from "@/api/system/dataModel";

export default {
  data() {
    return {
      visible: false,
      options: [],
      queryJson: {
        field: '',
        keyword: ""
      },
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc'
      },
      dataBase: '',
      table: '',
      list: []
    }
  },
  methods: {
    goBack() {
      this.$emit('close')
    },
    reset() {
      this.queryJson.keyword = '';
      // this.queryJson.field = '';
      this.search()
    },
    search() {
      this.listQuery = {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc'
      }
      this.initData()
    },
    cellDblclick(row, column, cell, event) {
      if (event.type == "dblclick") {
        var save = function (e) {
          e.clipboardData.setData("text/plain", event.target.innerText)
          e.preventDefault()
        }
        document.addEventListener("copy", save)
        document.execCommand("copy")
        document.removeEventListener("copy", save)
      }
      this.$message({message: '复制成功', type: 'success'})
    },
    init(dataBase, table) {
      if (!dataBase || !table) {
        this.$emit('close')
        return
      }
      this.dataBase = dataBase
      this.table = table.table_name
      this.$nextTick(() => {
        this.reset();
      })
    },
    initData() {
      // let query = {
      //   ...this.listQuery,
      //   ...this.queryJson
      // }
      // this.listLoading = true
      // this.list = []
      // openTable(this.dataBase, this.table, query).then(res => {
      //   this.list = res.data.list
      //   this.total = res.data.pagination.total
      //   this.listLoading = false
      // }).catch(() => {
      //   this.listLoading = false
      // })

      openTable(this.dataBase, {
        tableName: this.table,
        field: this.queryJson.field,
        keyword: this.queryJson.keyword,
      }).then(res => {
        this.options = res.data.data.colList;
        this.list = res.data.data.dataList;
        this.queryJson.field = this.options[0].Field
        this.listLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination-container {
  border-top: 1px solid #EBEEF5;
  padding-top: 10px;
}
</style>
