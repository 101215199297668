var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _c("div", { staticClass: "SNOWS-preview-main" }, [
      _c(
        "div",
        { staticClass: "SNOWS-common-page-header" },
        [
          _c("el-page-header", {
            attrs: { content: !_vm.dataForm.table ? "新建表名" : "编辑表名" },
            on: { back: _vm.goBack },
          }),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.dataFormSubmit()
                    },
                  },
                },
                [_vm._v("\n            确定\n          ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticClass: "mt-20",
              attrs: {
                model: _vm.dataForm,
                rules: _vm.dataRule,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "表名称", prop: "newTable" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.dataForm.table ? true : false,
                              maxlength: "50",
                              placeholder: "请输入表名称",
                            },
                            model: {
                              value: _vm.dataForm.newTable,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "newTable", $$v)
                              },
                              expression: "dataForm.newTable",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "表说明", prop: "tableName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入表说明" },
                            model: {
                              value: _vm.dataForm.tableName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "tableName", $$v)
                              },
                              expression: "dataForm.tableName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "SNOWS-common-title",
              staticStyle: { padding: "0 10px" },
            },
            [
              _c("h2", [_vm._v("字段设置")]),
              _c(
                "div",
                { staticClass: "options" },
                [
                  _c(
                    "el-dropdown",
                    { attrs: { disabled: !_vm.fieldList.length } },
                    [
                      _c(
                        "el-button",
                        { attrs: { disabled: !_vm.fieldList.length } },
                        [
                          _vm._v("\n                常用字段"),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(_vm.fieldList, function (item) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: item.id,
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.addHandle(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.filedName) + "\n                "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-row",
            { staticClass: "SNOWS-common-search-box", attrs: { gutter: 16 } },
            [
              _c(
                "el-form",
                {
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "列名" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入列名" },
                            on: { input: _vm.search },
                            model: {
                              value: _vm.query.filedName,
                              callback: function ($$v) {
                                _vm.$set(_vm.query, "filedName", $$v)
                              },
                              expression: "query.filedName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.refreshTable
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "dragTable",
                  attrs: {
                    data: _vm.list,
                    "row-key": (row) => row.index,
                    size: "mini",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "序号",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "列名", prop: "filedName" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.disabled
                                ? _c("p", [_vm._v(_vm._s(scope.row.filedName))])
                                : _c("el-input", {
                                    attrs: {
                                      maxlength: "50",
                                      placeholder: "请输入列名",
                                    },
                                    model: {
                                      value: scope.row.filedName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "filedName", $$v)
                                      },
                                      expression: "scope.row.filedName",
                                    },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      664847190
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "类型", prop: "dataType" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.disabled
                                ? _c("p", [_vm._v(_vm._s(scope.row.dataType))])
                                : _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: scope.row.dataType,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "dataType", $$v)
                                        },
                                        expression: "scope.row.dataType",
                                      },
                                    },
                                    _vm._l(_vm.options, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      833935274
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "长度", prop: "length" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.disabled
                                ? _c("p", [_vm._v(_vm._s(scope.row.length))])
                                : _c("el-input", {
                                    attrs: {
                                      disabled:
                                        scope.row.dataType !== "varchar" &&
                                        scope.row.dataType !== "decimal",
                                      placeholder: "请输入长度",
                                    },
                                    model: {
                                      value: scope.row.length,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "length", $$v)
                                      },
                                      expression: "scope.row.length",
                                    },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3721180062
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "是否主键",
                      prop: "isMainKey",
                      width: "70",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.disabled
                                ? _c("el-checkbox", {
                                    attrs: { value: !!scope.row.isMainKey },
                                  })
                                : _c("el-checkbox", {
                                    attrs: {
                                      "false-label": false,
                                      "true-label": true,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeKey($event, scope.row)
                                      },
                                    },
                                    model: {
                                      value: scope.row.isMainKey,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "isMainKey", $$v)
                                      },
                                      expression: "scope.row.isMainKey",
                                    },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1617167577
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "说明", prop: "comment" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.disabled
                                ? _c("p", [_vm._v(_vm._s(scope.row.comment))])
                                : _c("el-input", {
                                    attrs: { placeholder: "请输入说明" },
                                    model: {
                                      value: scope.row.comment,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "comment", $$v)
                                      },
                                      expression: "scope.row.comment",
                                    },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4183860685
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "50" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              !scope.row.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "SNOWS-table-delBtn",
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDel(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除\n              ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      564765073
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "table-actions",
              on: {
                click: function ($event) {
                  return _vm.addHandle()
                },
              },
            },
            [
              _c(
                "el-button",
                { attrs: { icon: "el-icon-plus", type: "text" } },
                [_vm._v("新建字段")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }