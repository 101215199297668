var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _c("div", { staticClass: "SNOWS-preview-main" }, [
      _c(
        "div",
        { staticClass: "SNOWS-common-page-header" },
        [
          _c("el-page-header", {
            attrs: { content: _vm.table + "表的数据" },
            on: { back: _vm.goBack },
          }),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "0 10px 10px 10px" } },
        [
          _c(
            "el-row",
            { staticClass: "SNOWS-common-search-box", attrs: { gutter: 16 } },
            [
              _c(
                "el-form",
                {
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "字段" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择字段",
                              },
                              model: {
                                value: _vm.queryJson.field,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryJson, "field", $$v)
                                },
                                expression: "queryJson.field",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.Field,
                                attrs: { label: item.Field, value: item.Field },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键词" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入关键词查询",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search()
                              },
                            },
                            model: {
                              value: _vm.queryJson.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryJson, "keyword", $$v)
                              },
                              expression: "queryJson.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.search()
                                },
                              },
                            },
                            [_vm._v("\n                查询\n              ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-refresh-right" },
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v("重置\n              ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                size: "medium",
                border: "",
                height: "calc(100vh - 304px)",
              },
              on: { "cell-dblclick": _vm.cellDblclick },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-table-column", {
                key: item.Field,
                attrs: {
                  prop: item.Field,
                  label: item.Field,
                  "show-overflow-tooltip": "",
                  "min-width": "150",
                },
              })
            }),
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.currentPage,
              limit: _vm.listQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "currentPage", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              pagination: _vm.initData,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }